import { inject, observer } from "mobx-react";
import React from "react";
import { Text, View, TouchableOpacity, FlatList } from "react-native";

import FormulaStore from "./FormulaStore";
import curves from "./formula.json";
import { formula, formulas } from "./formulaType";
import i18n from "./i18n";
import styles from "./styles";

type HomeProps = {
    navigation: any;
    formulastore: FormulaStore;
};

@inject("formulastore")
@observer
export default class Home extends React.Component<HomeProps> {
    itemElement(item: formula) {
        const navigation = this.props.navigation;
        return (
            <TouchableOpacity
                onPress={() => {
                    this.props.formulastore.reset(item.input);
                    navigation.navigate("Detail", { ...item });
                }}>
                <Text style={styles.listitem}> {i18n(item.caption)}</Text>
            </TouchableOpacity>
        );
    }
    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    data={curves as formulas}
                    renderItem={({ item }) => this.itemElement(item)}
                />
            </View>
        );
    }
}
