import { Linking } from "expo";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { Text, View, KeyboardAvoidingView, FlatList, Platform } from "react-native";

import { InputsIgE, InputSex, InputAge, InputIgE, InputBoolean } from "./InputItems";
import ProbabilityCurve from "./ProbabilityCurve";
import styles from "./styles";

@inject("formulastore")
@observer
export default class Detail extends React.Component {
    itemElement(key: string, item: object): any {
        switch (item.type) {
            case "sIgE":
                return <InputsIgE key={key} xkey={key} {...item} />;
            case "sex":
                return <InputSex key={key} xkey={key} {...item} />;
            case "age":
                return <InputAge key={key} xkey={key} {...item} />;
            case "IgE":
                return <InputIgE key={key} xkey={key} {...item} />;
            case "boolean":
                return <InputBoolean key={key} xkey={key} {...item} />;
            default:
                return <Text key={key}>Unnown type: {item.type}</Text>;
        }
    }
    render() {
        const formula = this.props.route.params;
        const values = toJS(this.props.formulastore.values);
        return (
            <KeyboardAvoidingView
                style={{ flex: 1, justifyContent: "flex-end" }}
                behavior={Platform.OS === "ios" ? "padding" : null}
                keyboardVerticalOffset={48}>
                <ProbabilityCurve formula={formula} values={values} />
                <View style={{ flex: 1 }}>
                    <FlatList
                        data={Object.keys(formula.input)}
                        renderItem={({ item }) => this.itemElement(item, formula.input[item])}
                        keyExtractor={item => item}
                    />
                </View>
                <Text style={styles.footer}>
                    <Text
                        style={styles.link}
                        onPress={() => Linking.openURL("https://dx.doi.org/" + formula.doi)}>
                        {formula.citation}
                    </Text>
                    .
                </Text>
            </KeyboardAvoidingView>
        );
    }
}
