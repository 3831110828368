import "react-native-gesture-handler";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { Provider } from "mobx-react";
import React from "react";

import Detail from "./Detail";
import FormulaStore from "./FormulaStore";
import Home from "./Home";
import i18n from "./i18n";

const Stack = createStackNavigator();
export default function App() {
    return (
        <Provider formulastore={new FormulaStore()}>
            <NavigationContainer>
                <Stack.Navigator initialRouteName="Home">
                    <Stack.Screen
                        name="Home"
                        options={{ title: "Probability curve of OFC" }}
                        component={Home}
                    />
                    <Stack.Screen
                        name="Detail"
                        component={Detail}
                        options={({ route }) => ({ title: i18n(route.params.caption) })}
                    />
                </Stack.Navigator>
            </NavigationContainer>
        </Provider>
    );
}
