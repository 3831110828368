import { inject } from "mobx-react";
import React from "react";
import { Text, View, TouchableOpacity, TextInput, Switch } from "react-native";
import { Icon } from "react-native-elements";

import FormulaStore from "./FormulaStore";
import i18n, { i18nstring } from "./i18n";
import styles from "./styles";

class InputWithCaption extends React.Component<{ caption: string }> {
    render() {
        return (
            <View style={styles.inputwithcaption}>
                <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                    <Text style={styles.caption}>{this.props.caption}</Text>
                    {this.props.children}
                </View>
            </View>
        );
    }
}

@inject("formulastore")
export class InputsIgE extends React.Component<
    { xkey: string; caption: i18nstring; formulastore: FormulaStore },
    { text: string }
> {
    constructor(props: any) {
        super(props);
        this.state = { text: "0.0" };
    }
    render() {
        return (
            <InputWithCaption caption={i18n(this.props.caption)}>
                <TextInput
                    style={styles.inputtextitem}
                    placeholder="0.0"
                    keyboardType="numeric"
                    onChangeText={text => {
                        const value = parseFloat(text) || 0.0;
                        this.setState({ text });
                        this.props.formulastore.setValue(this.props.xkey, value);
                    }}
                    value={this.state.text}
                />
            </InputWithCaption>
        );
    }
}

// male = true, female = false
@inject("formulastore")
export class InputSex extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: true };
    }
    render() {
        const value = this.state.value;

        return (
            <InputWithCaption caption={i18n("#Sex")}>
                <TouchableOpacity
                    style={styles.inputitem}
                    onPress={() => {
                        const newvalue = !value;
                        this.props.formulastore.setValue(this.props.xkey, newvalue);
                        this.setState({ value: newvalue });
                    }}>
                    <View style={{ flex: 1, flexDirection: "row" }}>
                        <Icon
                            style={{ width: 32, padding: 8 }}
                            name={value ? "male" : "female"}
                            color={value ? "#0cf" : "#f88"}
                            type="font-awesome"
                        />
                        <Text> </Text>
                        <Text style={{ fontSize: 24 }}>{i18n(value ? "#Male" : "#Female")}</Text>
                    </View>
                </TouchableOpacity>
            </InputWithCaption>
        );
    }
}

@inject("formulastore")
export class InputAge extends React.Component {
    constructor(props) {
        super(props);
        this.state = { text: "0.0" };
    }
    render() {
        return (
            <InputWithCaption caption={i18n("#Age")}>
                <TextInput
                    style={styles.inputtextitem}
                    placeholder="0.0"
                    keyboardType="numeric"
                    onChangeText={text => {
                        const value = parseFloat(text) || 0.0;
                        this.setState({ text });
                        this.props.formulastore.setValue(this.props.xkey, value);
                    }}
                    value={this.state.text}
                />
            </InputWithCaption>
        );
    }
}

@inject("formulastore")
export class InputIgE extends React.Component {
    constructor(props) {
        super(props);
        this.state = { text: "100", value: 100 };
    }
    render() {
        return (
            <InputWithCaption caption={i18n("#TotalIgE")}>
                <TextInput
                    style={styles.inputtextitem}
                    placeholder="100"
                    keyboardType="numeric"
                    onChangeText={text => {
                        const value = parseFloat(text) || 0.0;
                        this.setState({ text });
                        this.props.formulastore.setValue(this.props.xkey, value);
                    }}
                    value={this.state.text}
                />
            </InputWithCaption>
        );
    }
}

@inject("formulastore")
export class InputBoolean extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: false };
    }
    render() {
        return (
            <InputWithCaption caption={i18n(this.props.caption)}>
                <Switch
                    style={styles.inputitem}
                    onValueChange={value => {
                        this.props.formulastore.setValue(this.props.xkey, value);
                        this.setState({ value });
                    }}
                    value={this.state.value}
                />
            </InputWithCaption>
        );
    }
}
