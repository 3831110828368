import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Svg, { Rect, Line, Circle, Polyline } from "react-native-svg";

import styles from "./styles";

export default class ProbabilityCurve extends React.Component {
    /*
     *         P
     *  log ------- = alpha + beta * x1 + beta * x2 ...
     *       1 - P
     */
    probability(sIgE) {
        const LP = Math.exp(this.logitP(sIgE));
        return LP / (LP + 1);
    }
    logitP(sIgE) {
        let beta_value = 0;
        for (let [key, beta] of Object.entries(this.props.formula.beta)) {
            const valueType = this.props.formula.input[key].type;
            if (key === "sIgE") {
                beta_value += beta * Math.log10(Math.max(0.01, sIgE));
            } else if (valueType === "sIgE" || valueType === "IgE") {
                beta_value += beta * Math.log10(Math.max(0.01, this.props.values[key]));
            } else {
                beta_value += beta * this.props.values[key];
            }
        }
        return this.props.formula.intercept + beta_value;
    }
    sIgEToX(sIgE) {
        const logSIgE = Math.log10(sIgE);
        const X = 40 * logSIgE + 80;
        if (X === undefined || Number.isNaN(X) || !Number.isFinite(X)) {
            return 0;
        } else {
            return X;
        }
    }
    probToY(prob) {
        return 150 * (1 - prob) || 150;
    }
    render() {
        // TODO: Text!!!!
        let probPoints = "";
        for (let i = -2.0; i <= 3.1; i += 0.2) {
            const sIgE = Math.pow(10, i);
            const x = this.sIgEToX(sIgE).toFixed(3);
            const y = this.probToY(this.probability(sIgE)).toFixed(3);
            probPoints += x + "," + y + " ";
        }
        return (
            <View style={{ height: 224 }}>
                <View style={{ width: "100%", height: 200 }}>
                    <View style={[StyleSheet.absoluteFill, { backgroundColor: "#cef" }]}>
                        <Svg width="100%" height="100%" viewBox="-20 -10 240 170">
                            <Rect
                                x="0"
                                y="0"
                                width="200"
                                height="150"
                                strokeWidth="0"
                                fill="white"
                            />
                            {[0, 1, 2, 3, 4].map(i => (
                                <Line
                                    key={i}
                                    x1="0"
                                    y1={(150 / 4) * i}
                                    x2="200"
                                    y2={(150 / 4) * i}
                                    stroke="#8c8"
                                    strokeWidth="0.5"
                                    strokeDasharray="1 3"
                                />
                            ))}
                            {[0, 1, 2, 3, 4, 5].map(i => (
                                <Line
                                    key={i}
                                    x1={(200 / 5) * i}
                                    y1="0"
                                    x2={(200 / 5) * i}
                                    y2="150"
                                    stroke="#88f"
                                    strokeWidth="0.5"
                                    strokeDasharray="1 3"
                                />
                            ))}
                            <Rect
                                x="0"
                                y="0"
                                width="200"
                                height="150"
                                stroke="#003"
                                strokeWidth="1"
                                fillOpacity="0"
                            />
                            <Polyline
                                points={probPoints}
                                fill="none"
                                stroke="#08c"
                                strokeWidth="2"
                                strokeDasharray="2 2"
                                strokeOpacity="0.8"
                            />
                            <Circle
                                cx={this.sIgEToX(this.props.values.sIgE)}
                                cy={this.probToY(this.probability(this.props.values.sIgE))}
                                r="2"
                                stroke="red"
                                fill="red"
                                fillOpacity="0.6"
                            />
                        </Svg>
                    </View>
                </View>
                <Text style={styles.probresult}>
                    Probability of failing OFC is:{" "}
                    {(this.probability(this.props.values.sIgE) * 100).toFixed(1)} %
                </Text>
            </View>
        );
    }
}
