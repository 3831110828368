import { observable, action } from "mobx";

import { inputItem } from "./formulaType";

export default class FormulaStore {
    @observable values = {};
    @action setValue = (key: string, value: any): void => {
        this.values[key] = value;
    };
    defaultOf(inputType: string): any {
        switch (inputType) {
            case "age":
            case "sIgE":
                return 0.0;
            case "IgE":
                return 100.0;
            case "sex":
                return true;
            case "boolean":
                return false;
            default:
                console.error("Unknown type: no default value.");
                return undefined;
        }
    }
    @action reset = (input: inputItem) => {
        this.values = {};
        for (let [key, value] of Object.entries(input)) {
            this.values[key] = this.defaultOf(value.type);
        }
    };
}
