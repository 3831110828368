import * as Localization from "expo-localization";

import translations from "./translations.json";

declare namespace i18n {
    let lang: string;
}

export type i18nstring = object | string;

function i18n(text: i18nstring, lang?: string) {
    if (i18n.lang === undefined) {
        i18n.lang = Localization.locale;
        if (i18n.lang === "en") i18n.lang = "en-US";
        if (i18n.lang === "ja") i18n.lang = "ja-JP";
        if (i18n.lang === undefined) i18n.lang = "en-US";
    }

    if (lang === undefined) {
        lang = i18n.lang;
    }

    if (typeof text === "object") {
        return text[lang] || text["en-US"] || "<Unknown translation>";
    } else if (text in translations) {
        return i18n(translations[text], lang);
    } else {
        return text;
    }
}

export default i18n;
