import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff",
    },
    listitem: {
        fontSize: 24,
        padding: 8,
        margin: 8,
    },
    emphasize: {
        color: "#c33",
        fontWeight: "700" /* bold */,
    },
    link: {
        color: "#03c",
        fontWeight: "700" /* bold */,
        textDecorationLine: "underline",
    },
    probresult: {
        paddingLeft: 16,
        fontSize: 16,
    },
    inputwithcaption: {
        height: 48,
        paddingLeft: 16,
        // backgroundColor: "#cff"
    },
    caption: {
        flex: 5,
    },
    inputtextitem: {
        flex: 5,
        height: 32,
        fontSize: 24,
    },
    inputitem: {
        flex: 5,
        height: 32,
    },
    footer: {
        backgroundColor: "#fff",
        height: 48,
        padding: 16,
    },
});

export default styles;
